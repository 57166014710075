.banner-container {
  position: relative;
  text-align: center;
  color: white;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image covers the container */
}

@media (max-width: 767px) {
  .banner-container {
    height: 200px;
  }
  .banner-image {
    height: 200px;
  }
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.banner-title {
  font-size: 3em; /* Large text for the main title */
  animation: fadeInUp 1s ease;
}

.banner-subtitle {
  font-size: 1.5em; /* Smaller text for the subtitle */
  animation: fadeIn 2s ease;
}

/* Simple fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade-in from below animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .banner-title {
    font-size: 2em;
  }
  .banner-subtitle {
    font-size: 1em;
  }
}

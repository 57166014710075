.certificate-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 8px;
    background: white;
    transition: transform 0.3s ease;
  }
  
  .certificate-container:hover {
    transform: translateY(-10px);
  }
  
  .certificate-details h2, .certificate-details h3 {
    color: #3498db;
    margin-bottom: 10px;
  }
  
  .certificate-details p, .certificate-details li {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .certificate-details ul {
    list-style: none;
    padding: 0;
  }
  
  .certificate-details li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .loading, .error {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .certificate-container {
      margin: 10px;
      padding: 15px;
    }
  }
  .certificate-details-card {
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .certificate-details-card .card-title {
    color: #3498db;
    font-size: 1.5em;
  }
  
  .certificate-details-card .card-text {
    font-size: 1em;
    line-height: 1.6;
    color: #333;
  }
  .button-row .btn {
    width: 100%; /* Full width for each button */
  }
  
  @media (min-width: 768px) {
    .button-row .btn {
      width: auto; /* Auto width for larger screens */
    }
  }
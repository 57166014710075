/* Example styles for the table */
.grades-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .grades-table th, .grades-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .grades-table th {
    background-color: #f2f2f2;
  }
  
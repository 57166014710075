.header-row {
    align-items: center;
}

.header-link {
    margin: 0 10px;
    color: black;
    font-weight: 500;
    text-decoration: none;
}

.apply-button {
    border: 1px solid #a64929;
    background-color: #a64929;
    color: white;
    border-radius: 25px;
    width: 180px;
    font-weight: 500;
    padding: 6px 15px;
}

.apply-button a {
    text-decoration: none;
    color: white;
}

.apply-link {
    color: white;
    text-decoration: none;
}

@media (max-width: 768px) {
    .header-link {
        display: block; /* Stack the links vertically on small screens */
        margin: 5px 0;
    }

    .apply-button {
        width: 100%; /* Full width button on small screens */
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-icon {
  color: white;
  margin-left: 15px;
  font-size: 20px;
  transition: color 0.3s;
}

.header-icon:hover {
  color: #ddd;
  text-decoration: none;
}

@media (max-width: 768px) {
  .row.align-items-center {
      text-align: center;
  }

  .header-icon {
      margin: 0 10px;
  }
}

